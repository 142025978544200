@font-face {
  font-family: "HankenGrotesk";
  src: url("./Fonts/HankenGrotesk-Regular.otf");
}

@font-face {
  font-family: "Born";
  src: url("./Fonts/Born.otf");
}

@font-face {
  font-family: "Garamond";
  src: url("./Fonts/FreightTextMedium.otf");
}

@import url("https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap");

@media only print {
  .print {
    display: block;
  }

  .screen {
    display: none;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #ffe01b;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  padding-top: 100px;
}

.bgcolor {
  background-color: #ffe01b !important;
}

.globalNavBar {
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: center;
  top: 0;
  left: 0;
  transform-origin: 50% 0;
  transition: height 0.15s linear, background-color 0.15s linear;
  padding-left: 6.25rem;
  padding-right: 6.25rem;
  width: 100%;
  height: 5.3125rem;
  z-index: 100;
}

/*
.globalNavBar::before,
.globalNavBar::after {
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.globalNavBar::before {
  background-color: #fff;
  border-bottom: 0.0625rem solid #dbd9d2;
  bottom: -0.0625rem;
  height: calc(100% + 2.1875rem);
  transition: opacity 0.15s linear 0.1s;
  z-index: 0;
}
*/

.default-font {
  font-family: "Nunito Sans", sans-serif !important;
}

.title-font {
  font-family: "Baskerville", serif !important;
}

.menu-font {
  border-bottom-color: rgba(36, 28, 21, 0.65);
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgba(36, 28, 21, 0.65);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgba(36, 28, 21, 0.65);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgba(36, 28, 21, 0.65);
  border-top-style: none;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgba(36, 28, 21, 0.65);
  display: block;
  font-family: "Graphik Web", "Helvetica Neue", Helvetica, Arial, Verdana,
    sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 24px;
  line-height: 24px;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  text-decoration: none;
  text-decoration-color: rgba(36, 28, 21, 0.65);
  text-decoration-line: none;
  text-decoration-style: solid;
  -moz-osx-font-smoothing: grayscale;
}

.globalNavBar:hover {
  background-color: #ffffff !important;
}

.nav-link {
  color: #241c15 !important;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 0.9375rem;
}

.nav-link:hover {
  color: #007c89;
}

.login-button_manual {
  font-family: "Nunito Sans", sans-serif !important;
  font-weight: 600;
  -webkit-box-shadow: 0 0 0 0.0625rem rgba(36, 28, 21, 0.4) inset;
  box-shadow: inset 0 0 0 0.0625rem rgba(36, 28, 21, 0.4);
  color: #241c15;
  min-width: 7.5rem;
  margin-right: 20px;
  padding: 0.8125rem;
  background-color: transparent;
}

.signup-button_manual {
  -o-transition: all 0.15s linear;
  -webkit-box-shadow: 0 0 0 0.0625rem #007c89 inset;
  -webkit-transition: all 0.15s linear;
  background-color: #007c89;
  box-shadow: inset 0 0 0 0.0625rem #007c89;
  color: #fff;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 0.9375rem;
  font-weight: 600;
  padding: 1.25rem 2.5rem;
  transition: all 0.15s linear;
  min-width: 7.5rem;
  padding: 0.8125rem;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.button-primary {
  background-attachment: scroll;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  background-origin: padding-box;
  background-position: 0% 0%;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  border-bottom-color: rgb(36, 28, 21);
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(36, 28, 21);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgb(36, 28, 21);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgb(36, 28, 21);
  border-top-style: none;
  border-top-width: 0px;
  box-shadow: rgba(36, 28, 21, 0.4) 0px 0px 0px 1px inset;
  box-sizing: border-box;
  color: rgb(36, 28, 21);
  cursor: pointer;
  display: inline-block;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.25px;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 10px;
  margin-top: 0px;
  min-width: 120px;
  padding-bottom: 13px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 13px;
  text-align: center;
  text-decoration: none;
  text-decoration-color: rgb(36, 28, 21);
  text-decoration-line: none;
  text-decoration-style: solid;
  transition-delay: 0s;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: linear;
  vertical-align: baseline;
  -moz-appearance: none;
}

.button-secondary {
  background-attachment: scroll;
  background-clip: border-box;
  background-color: rgb(0, 124, 137);
  background-image: none;
  background-origin: padding-box;
  background-position: 0% 0%;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(255, 255, 255);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgb(255, 255, 255);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgb(255, 255, 255);
  border-top-style: none;
  border-top-width: 0px;
  box-shadow: rgb(0, 124, 137) 0px 0px 0px 1px inset;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.25px;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  min-width: 120px;
  padding-bottom: 13px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 13px;
  text-align: center;
  text-decoration: none;
  text-decoration-color: rgb(255, 255, 255);
  text-decoration-line: none;
  text-decoration-style: solid;
  transition-delay: 0s;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: linear;
  vertical-align: baseline;
  visibility: visible;
  -moz-appearance: none;
}

.button-secondary:hover {
  background-color: #006570;
}

.signup-form-input {
  background-color: rgb(255, 255, 255);
  border-bottom-color: rgba(18, 77, 80, 0.467);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgba(18, 77, 80, 0.467);
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: rgba(18, 77, 80, 0.467);
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: rgba(18, 77, 80, 0.467);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-top-style: solid;
  border-top-width: 1px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  display: block;
  font-family: "Nunito Sans", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 24px;
  max-width: 635px;
  min-height: 52px;
  min-width: 50px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;
  position: relative;
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  width: 540px;
  -moz-appearance: none;
  -moz-osx-font-smoothing: grayscale;
}
