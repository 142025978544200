div {
  font-family: "Helvetica";
  font-size: 14px;
  /* border: 1px solid blue; */
}

.logo {
  /*margin-top: 65mm;*/
  margin-top: 43mm;
  margin-bottom: 0mm;
}

.logo_rotated {
  margin-bottom: 0mm;
  margin-top: 15mm;
}

.logo img,
.logo_rotated img {
  width: 79.5mm;
  height: 18.75mm;
}

.ime,
.ime_rotated,
.prezime,
.prezime_rotated,
.tvrtka,
.tvrtka_rotated,
.mjesto,
.mjesto_rotated,
.friendlyid,
.posjetitelj,
.posjetitelj_rotate {
  text-transform: uppercase;
}

.rotate {
  transform: rotate(180deg);
}

.posjetitelj,
.posjetitelj_rotate {
  font-family: "HelveticaNeueBold";
  background-color: #000000;
  -webkit-print-color-adjust: exact;
  color: #ffffff;
  width: 80%;
  text-align: center;
  margin: 0px auto;
  font-size: 19.3pt;
  letter-spacing: 1px;
}

.posjetitelj {
  margin-top: 5px;
  margin-bottom: 1.5mm;
  font-family: "Helvetica";
  font-weight: 700;
}

.posjetitelj_rotate {
  margin-top: 5px;
  margin-bottom: 1.5mm;
  font-family: "Helvetica";
  font-weight: 700;
}

.ime,
.ime_rotated,
.prezime,
.prezime_rotated {
  font-family: "Helvetica";
  font-weight: 700;
  font-size: 24pt;
}

.ime {
  /*margin-top: 8.5mm;*/
  margin-top: 13.5mm;
  line-height: 1;
}

.ime_rotated {
  margin-top: 0mm;
  margin-bottom: 8mm;
  line-height: 1;
}

.prezime {
  margin-top: 0mm;
  line-height: 1;
}

.prezime_rotated {
  margin-bottom: 0mm;
  line-height: 1;
}

.tvrtka {
  font-size: 16pt;
  margin-top: 5mm;
  /*width: 90%;*/
}

.tvrtka-smanjeno {
  font-size: 10pt;
  margin-top: 5mm;
  text-transform: uppercase;
}

.tvrtka_rotated {
  font-size: 16pt;
  margin-bottom: 5mm;
  /*width: 90%;*/
}

.mjesto,
.mjesto_rotated {
  font-size: 10pt;
}

.mjesto,
.mjesto_rotated {
  margin-top: 4mm;
  /*margin-bottom: 5mm;*/
  margin-bottom: 2mm;
}

.mjesto {
  /*margin-top: 5mm;
    margin-bottom: 4mm;*/
  margin-top: 0mm;
  /*margin-bottom: 9mm;*/
  margin-bottom: 12mm;
}

/* dodano */
.mjesto_rotated {
  /*margin-top: 9mm;*/
  margin-top: 9mm;
  margin-bottom: 0mm;
}
/* */

.friendlyid {
  font-family: "Helvetica";
  font-size: 9pt;
  font-weight: 500;
  text-align: left;
  margin-left: 159px;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.friendlyid_rotate {
  font-family: "Helvetica";
  font-size: 9pt;
  font-weight: 500;
  text-align: left;
  margin-right: 159px;
  margin-top: 2px;
  text-transform: uppercase;
}

@media print {
  * {
    transform: scale(1.05);
  }

  .screen {
    display: none !important;
  }

  canvas {
    width: 35mm;
  }

  @page {
    size: 100mm 235mm;
    margin: 0mm 5mm 0mm 5mm;
  }
}

@media screen {
  #print {
    /*display: none;*/
    margin: 10mm 10mm 10mm 10mm;
  }
}
