.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.navbar {
  margin-bottom: 40px;
}

.nav-item {
  margin-left: 20px;
  margin-right: 20px;
}

.nav-item a {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 0.9rem;
}

input[type="checkbox"] {
  margin-right: 5px;
}

.event-izlagaci-row {
  cursor: pointer;
}

.novi-event-import-row td {
  font-size: 14px !important;
}

.event-izlagaci-row:hover {
  background-color: bisque;
}

.izlagac-details-kategorije-naslov {
  font-size: 14px;
  font-weight: 700;
}

.izlagac-details-kategorije-tekst {
  font-size: 12px;
}

ul {
  list-style: none;
}

.naslov-popis {
  text-align: left;
}

.tekst-event-osobe,
.tekst-event-scanulaz,
.tekst-event-sastanci {
  font-size: 14px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
