.upis-eventa {
  text-align: right;
}

.event-list-row {
  cursor: pointer;
}

.event-list-row:hover {
  background-color: bisque;
}
