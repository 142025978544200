.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
}

.modal-main {
  position: fixed;
  background: white;
  width: 750px;
  height: 650px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  /* border: 2px solid purple;
  border-radius: 2px; */
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
