@import url(https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap);
@font-face {
  font-family: "HankenGrotesk";
  src: url(/static/media/HankenGrotesk-Regular.3f24447a.otf);
}

@font-face {
  font-family: "Born";
  src: url(/static/media/Born.aa1faf87.otf);
}

@font-face {
  font-family: "Garamond";
  src: url(/static/media/FreightTextMedium.e29b058f.otf);
}

@media only print {
  .print {
    display: block;
  }

  .screen {
    display: none;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #ffe01b;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  padding-top: 100px;
}

.bgcolor {
  background-color: #ffe01b !important;
}

.globalNavBar {
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: center;
  top: 0;
  left: 0;
  transform-origin: 50% 0;
  transition: height 0.15s linear, background-color 0.15s linear;
  padding-left: 6.25rem;
  padding-right: 6.25rem;
  width: 100%;
  height: 5.3125rem;
  z-index: 100;
}

/*
.globalNavBar::before,
.globalNavBar::after {
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.globalNavBar::before {
  background-color: #fff;
  border-bottom: 0.0625rem solid #dbd9d2;
  bottom: -0.0625rem;
  height: calc(100% + 2.1875rem);
  transition: opacity 0.15s linear 0.1s;
  z-index: 0;
}
*/

.default-font {
  font-family: "Nunito Sans", sans-serif !important;
}

.title-font {
  font-family: "Baskerville", serif !important;
}

.menu-font {
  border-bottom-color: rgba(36, 28, 21, 0.65);
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgba(36, 28, 21, 0.65);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgba(36, 28, 21, 0.65);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgba(36, 28, 21, 0.65);
  border-top-style: none;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgba(36, 28, 21, 0.65);
  display: block;
  font-family: "Graphik Web", "Helvetica Neue", Helvetica, Arial, Verdana,
    sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 24px;
  line-height: 24px;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  text-decoration: none;
  text-decoration-color: rgba(36, 28, 21, 0.65);
  text-decoration-line: none;
  text-decoration-style: solid;
  -moz-osx-font-smoothing: grayscale;
}

.globalNavBar:hover {
  background-color: #ffffff !important;
}

.nav-link {
  color: #241c15 !important;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 0.9375rem;
}

.nav-link:hover {
  color: #007c89;
}

.login-button_manual {
  font-family: "Nunito Sans", sans-serif !important;
  font-weight: 600;
  box-shadow: inset 0 0 0 0.0625rem rgba(36, 28, 21, 0.4);
  color: #241c15;
  min-width: 7.5rem;
  margin-right: 20px;
  padding: 0.8125rem;
  background-color: transparent;
}

.signup-button_manual {
  -o-transition: all 0.15s linear;
  -webkit-box-shadow: 0 0 0 0.0625rem #007c89 inset;
  -webkit-transition: all 0.15s linear;
  background-color: #007c89;
  box-shadow: inset 0 0 0 0.0625rem #007c89;
  color: #fff;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 0.9375rem;
  font-weight: 600;
  padding: 1.25rem 2.5rem;
  transition: all 0.15s linear;
  min-width: 7.5rem;
  padding: 0.8125rem;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.button-primary {
  background-attachment: scroll;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  background-origin: padding-box;
  background-position: 0% 0%;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  border-bottom-color: rgb(36, 28, 21);
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(36, 28, 21);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgb(36, 28, 21);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgb(36, 28, 21);
  border-top-style: none;
  border-top-width: 0px;
  box-shadow: rgba(36, 28, 21, 0.4) 0px 0px 0px 1px inset;
  box-sizing: border-box;
  color: rgb(36, 28, 21);
  cursor: pointer;
  display: inline-block;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.25px;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 10px;
  margin-top: 0px;
  min-width: 120px;
  padding-bottom: 13px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 13px;
  text-align: center;
  text-decoration: none;
  text-decoration-color: rgb(36, 28, 21);
  text-decoration-line: none;
  text-decoration-style: solid;
  transition-delay: 0s;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: linear;
  vertical-align: baseline;
  -moz-appearance: none;
}

.button-secondary {
  background-attachment: scroll;
  background-clip: border-box;
  background-color: rgb(0, 124, 137);
  background-image: none;
  background-origin: padding-box;
  background-position: 0% 0%;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(255, 255, 255);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgb(255, 255, 255);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgb(255, 255, 255);
  border-top-style: none;
  border-top-width: 0px;
  box-shadow: rgb(0, 124, 137) 0px 0px 0px 1px inset;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.25px;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  min-width: 120px;
  padding-bottom: 13px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 13px;
  text-align: center;
  text-decoration: none;
  text-decoration-color: rgb(255, 255, 255);
  text-decoration-line: none;
  text-decoration-style: solid;
  transition-delay: 0s;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: linear;
  vertical-align: baseline;
  visibility: visible;
  -moz-appearance: none;
}

.button-secondary:hover {
  background-color: #006570;
}

.signup-form-input {
  background-color: rgb(255, 255, 255);
  border-bottom-color: rgba(18, 77, 80, 0.467);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgba(18, 77, 80, 0.467);
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: rgba(18, 77, 80, 0.467);
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: rgba(18, 77, 80, 0.467);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-top-style: solid;
  border-top-width: 1px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  display: block;
  font-family: "Nunito Sans", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 24px;
  max-width: 635px;
  min-height: 52px;
  min-width: 50px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;
  position: relative;
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  width: 540px;
  -moz-appearance: none;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.navbar {
  margin-bottom: 40px;
}

.nav-item {
  margin-left: 20px;
  margin-right: 20px;
}

.nav-item a {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 0.9rem;
}

input[type="checkbox"] {
  margin-right: 5px;
}

.event-izlagaci-row {
  cursor: pointer;
}

.novi-event-import-row td {
  font-size: 14px !important;
}

.event-izlagaci-row:hover {
  background-color: bisque;
}

.izlagac-details-kategorije-naslov {
  font-size: 14px;
  font-weight: 700;
}

.izlagac-details-kategorije-tekst {
  font-size: 12px;
}

ul {
  list-style: none;
}

.naslov-popis {
  text-align: left;
}

.tekst-event-osobe,
.tekst-event-scanulaz,
.tekst-event-sastanci {
  font-size: 14px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.event-djelatnosti-row {
  cursor: pointer;
}

.event-djelatnosti-row:hover {
  background-color: bisque;
}

.event-kategorije-row {
  cursor: pointer;
}

.event-kategorije-row:hover {
  background-color: bisque;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
}

.modal-main {
  position: fixed;
  background: white;
  width: 750px;
  height: 650px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  /* border: 2px solid purple;
  border-radius: 2px; */
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

div {
  font-family: "Helvetica";
  font-size: 14px;
  /* border: 1px solid blue; */
}

.logo {
  /*margin-top: 65mm;*/
  margin-top: 43mm;
  margin-bottom: 0mm;
}

.logo_rotated {
  margin-bottom: 0mm;
  margin-top: 15mm;
}

.logo img,
.logo_rotated img {
  width: 79.5mm;
  height: 18.75mm;
}

.ime,
.ime_rotated,
.prezime,
.prezime_rotated,
.tvrtka,
.tvrtka_rotated,
.mjesto,
.mjesto_rotated,
.friendlyid,
.posjetitelj,
.posjetitelj_rotate {
  text-transform: uppercase;
}

.rotate {
  transform: rotate(180deg);
}

.posjetitelj,
.posjetitelj_rotate {
  font-family: "HelveticaNeueBold";
  background-color: #000000;
  -webkit-print-color-adjust: exact;
  color: #ffffff;
  width: 80%;
  text-align: center;
  margin: 0px auto;
  font-size: 19.3pt;
  letter-spacing: 1px;
}

.posjetitelj {
  margin-top: 5px;
  margin-bottom: 1.5mm;
  font-family: "Helvetica";
  font-weight: 700;
}

.posjetitelj_rotate {
  margin-top: 5px;
  margin-bottom: 1.5mm;
  font-family: "Helvetica";
  font-weight: 700;
}

.ime,
.ime_rotated,
.prezime,
.prezime_rotated {
  font-family: "Helvetica";
  font-weight: 700;
  font-size: 24pt;
}

.ime {
  /*margin-top: 8.5mm;*/
  margin-top: 13.5mm;
  line-height: 1;
}

.ime_rotated {
  margin-top: 0mm;
  margin-bottom: 8mm;
  line-height: 1;
}

.prezime {
  margin-top: 0mm;
  line-height: 1;
}

.prezime_rotated {
  margin-bottom: 0mm;
  line-height: 1;
}

.tvrtka {
  font-size: 16pt;
  margin-top: 5mm;
  /*width: 90%;*/
}

.tvrtka-smanjeno {
  font-size: 10pt;
  margin-top: 5mm;
  text-transform: uppercase;
}

.tvrtka_rotated {
  font-size: 16pt;
  margin-bottom: 5mm;
  /*width: 90%;*/
}

.mjesto,
.mjesto_rotated {
  font-size: 10pt;
}

.mjesto,
.mjesto_rotated {
  margin-top: 4mm;
  /*margin-bottom: 5mm;*/
  margin-bottom: 2mm;
}

.mjesto {
  /*margin-top: 5mm;
    margin-bottom: 4mm;*/
  margin-top: 0mm;
  /*margin-bottom: 9mm;*/
  margin-bottom: 12mm;
}

/* dodano */
.mjesto_rotated {
  /*margin-top: 9mm;*/
  margin-top: 9mm;
  margin-bottom: 0mm;
}
/* */

.friendlyid {
  font-family: "Helvetica";
  font-size: 9pt;
  font-weight: 500;
  text-align: left;
  margin-left: 159px;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.friendlyid_rotate {
  font-family: "Helvetica";
  font-size: 9pt;
  font-weight: 500;
  text-align: left;
  margin-right: 159px;
  margin-top: 2px;
  text-transform: uppercase;
}

@media print {
  * {
    transform: scale(1.05);
  }

  .screen {
    display: none !important;
  }

  canvas {
    width: 35mm;
  }

  @page {
    size: 100mm 235mm;
    margin: 0mm 5mm 0mm 5mm;
  }
}

@media screen {
  #print {
    /*display: none;*/
    margin: 10mm 10mm 10mm 10mm;
  }
}

.upis-osobe {
  font-weight: 700;
}

.upis-admina {
  text-align: right;
}

.upis-eventa {
  text-align: right;
}

.event-list-row {
  cursor: pointer;
}

.event-list-row:hover {
  background-color: bisque;
}

.upis-organizatora {
  text-align: right;
}

.nav-item {
  text-align: left !important;
}
.navi-tem a {
  font-size: 0.9rem;
}

td {
  font-size: 23px !important;
  text-align: left;
  width: 400px;
}

